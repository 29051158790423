<template>
    <AppPaginatedGrid
        ref="grid"
        :service="service"
        newPath="/manuais/new"
        editPath="/manuais/"
        subtitle="Manuais do sistema"
        tooltip="Manuais do sistema cadastrados por módulos/tela"
        nomeTelaDoManual="manuais-list"
    >
        <template #columns>
            <Column field="id" header="Código" :sortable="true" sortField="id"></Column>
            <Column field="descricao" header="Descrição" :sortable="true" sortField="descricao"></Column>
            <Column field="modulo" header="Módulo" :sortable="true" sortField="modulo"></Column>
            <Column field="tela" header="Tela" :sortable="true" sortField="tela"></Column>
            <Column field="createdAt" header="Criado" :sortable="true" sortField="created_at">
                <template #body="slotProps">
                    {{ $filters.formatDate(slotProps.data.createdAt) }}
                </template>
            </Column>
            <Column field="updatedAt" header="Atualizado" :sortable="true" sortField="updated_at">
                <template #body="slotProps">
                    {{ $filters.formatDate(slotProps.data.updatedAt) }}
                </template>
            </Column>
        </template>
    </AppPaginatedGrid>
</template>

<script>
import OrigemAnexoEnum from '../../enums/OrigemAnexoEnum';
import BaseService from '../../services/BaseService';

export default {
    data() {
        return {
            origemAnexoEnum: OrigemAnexoEnum,
            tenantCustomers: [],
            service: null
        };
    },
    created() {
        this.service = new BaseService('/manuais-sistema');
    },
    methods: {
        async load() {
            this.$refs.grid.load();
        }
    }
};
</script>
